import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Power Clean 6-6-6-6 @ 70% 1RM`}</p>
    <p>{`&`}</p>
    <p>{`Clean Grip RDL’s 6-6-6-6`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`6-Squat Cleans (155/105)`}</p>
    <p>{`18-Wall Balls (20/14`}{`#`}{`)`}</p>
    <p>{`54-Double Unders`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Open gym at Louisville East starting at 7:000am on today excluding
class times.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no 5:30pm class or open gym after 5:30 today at The
Ville so that we can set up for True Grit. If anyone is available to
help set up please show up at 5:30.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 4 is filled with teams ready to rumble for 1st place this
Saturday, November 5th! Come out and watch the action at The Ville
starting at 9:00am. For schedule and heat info go to our True Grit 4
Facebook page or `}<a parentName="em" {...{
            "href": "https://truegritcomp.wordpress.com"
          }}>{`https://truegritcomp.wordpress.com`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We are collecting blankets, scarves and gloves at Louisville East
and The Ville for the homeless this winter. If you’d like to donate
please do so by November 12th. There are bins at each location for your
donations. Thanks!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts Sunday, November 6th. For more
info contact Eric at fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      